import React from 'react'
import Layout from '../components/Layout/Layout'
import { News } from '../components/News/News'
import Title from '../components/Title/Title'

const NewsPage = () => {
  return (
    <Layout title="News">
      <Title>News</Title>

      <News/>
    </Layout>
  )
}

export default NewsPage
